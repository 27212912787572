import React from "react"
import { Box, Grid, Text } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import SwiperCore, { Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
SwiperCore.use([Mousewheel, Pagination, A11y])

const Gallery = ({ record }) => {
  // console.log(record)
  return (
    <>
      {record.layout ? (
        <Grid sx={{}} columns={[2, 3]} mb={5}>
          {record.images.map(image => (
            <Box key={image.id}>
              <GatsbyImage image={image.gatsbyImageData} />
              <Text as="p" sx={{ fontSize: 1, margin: 0 }}>
                {image.title}
              </Text>
            </Box>
          ))}
        </Grid>
      ) : (
        <Swiper
          spaceBetween={32}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {record.images.map(image => (
            <SwiperSlide key={image.id}>
              <Box
                key={image.id}
                sx={{
                  ".swiper-pagination-bullet-active": {
                    background: "#000000 !important",
                  },
                  ".gatsby-image-wrapper": {
                    width: "100%",
                    minHeight: "100%",
                  },
                }}
              >
                <GatsbyImage image={image.gatsbyImageData} />
                <Text as="p" sx={{ fontSize: 1, margin: 0 }}>
                  {image.title}
                </Text>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  )
}

export default Gallery
