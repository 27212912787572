import { Box, Container, Flex, Grid, Heading, Text } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { motion, AnimatePresence } from "framer-motion"

let AnimatedHaeding = motion(Heading)

const ArticleHero = ({ title, image, category, date }) => (
  <AnimatePresence>
    <Container>
      <Grid columns={[1, 1, 2]} backgroundColor="primary">
        <Box gap="0px" p={[4, 4, 4, 5]}>
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <AnimatedHaeding
              variant="display"
              as="h1"
              mb={4}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              exit={{ opacity: 0 }}
            >
              {title}
            </AnimatedHaeding>
            <Flex>
              <Text variant="caption" mr={2}>
                {category.name}
              </Text>
              <Text variant="date">{date}</Text>
            </Flex>
          </Flex>
        </Box>
        <GatsbyImage image={image.gatsbyImageData} alt="" />
      </Grid>
    </Container>
  </AnimatePresence>
)

export default ArticleHero
