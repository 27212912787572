import React from "react"
import { Link, Image, Text, Box } from "@theme-ui/components"

const ImageBlock = ({ record }) => {
  //   console.log(record)
  return (
    <>
      {record.url ? (
        <Link
          href={record.url}
          target={record.blank ? "blank" : null}
          sx={{ display: "block", textDecoration: "none" }}
        >
          <Box mb={4}>
            <Image
              src={record.image.url}
              alt={record.image.alt}
              sx={{ width: "100%" }}
            />
            <Text as="p" sx={{ textAlign: "center", fontSize: 1, mt: 2 }}>
              {record.image.title}
            </Text>
          </Box>
        </Link>
      ) : (
        <Box mb={4}>
          <Image
            src={record.image.url}
            alt={record.image.alt}
            sx={{ width: "100%" }}
          />
          <Text as="p" sx={{ textAlign: "center", fontSize: 1, mt: 2 }}>
            {record.image.title}
          </Text>
        </Box>
      )}
    </>
  )
}

export default ImageBlock
