import { Box } from "@theme-ui/components"
import React from "react"

const VideoEmbedBlock = ({ record }) => {
  // console.log(record)
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "0px",
        paddingBottom: "56.25%",
        iframe: {
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        },
      }}
      dangerouslySetInnerHTML={{ __html: record.embedCode }}
    />
  )
}

export default VideoEmbedBlock
