import React from "react"
import { Box } from "@theme-ui/components"

const TableBlock = ({ record }) => {
//   console.log(record)
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: record.body }}
      sx={{
        table: { width: "100%", borderColor: "#cccccc" },
        td: {
          padding: [2],
        },
        mb: [4]
      }}
    />
  )
}

export default TableBlock
