import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { StructuredText } from "react-datocms"
import { Container, Text } from "@theme-ui/components"
import {
  isHeading,
  isParagraph,
  renderRule,
} from "datocms-structured-text-utils"
import ArticleHero from "../components/articleHero"
import HighlightText from "../components/highlightText"
import SEO from "../components/seo"
import ImageBlock from "../components/imageBlock"
import TableBlock from "../components/tableBlock"
import Gallery from "../components/gallery"
import VideoBlock from "../components/videoBlock"
import VideoEmbedBlock from "../components/videoEmbedBlock"
import { LocaleContext } from "../context/localeContext"
import { getArticlePath } from "../utils/path"
import { i18nContext, languages } from "../context/i18nContext"

const Article = ({ data: { article }, location }) => {
  const allSlugLocales = article._allSlugLocales.map(page => {
    return {
      locale: page.locale,
      value: getArticlePath(page.value, page.locale),
    }
  })
  return (
    <LocaleContext.Provider
      value={{ activeLocale: article.locale, paths: allSlugLocales }}
    >
      <i18nContext.Provider value={languages[article.locale]}>
        <Layout
          locale={article.locale}
          location={location}
          allSlugLocales={article._allSlugLocales}
        >
          <SEO lang={article.locale} title={article.title} />
          <ArticleHero
            title={article.title}
            image={article.image}
            category={article.category}
            date={article.date}
          />
          <Container variant="article">
            <StructuredText
              data={article.structuredBody}
              customRules={[
                renderRule(
                  isHeading,
                  ({ adapter: { renderNode }, node, children, key }) => {
                    return renderNode(
                      () => {
                        return (
                          <Text
                            as={`h${node.level}`}
                            variant={`h${node.level}`}
                          >
                            {children}
                          </Text>
                        )
                      },
                      { key },
                      children
                    )
                  }
                ),
                renderRule(
                  isParagraph,
                  ({ adapter: { renderNode }, node, children, key }) => {
                    return renderNode(
                      () => {
                        return <Text as="p">{children}</Text>
                      },
                      { key },
                      children
                    )
                  }
                ),
              ]}
              renderBlock={({ record }) => {
                switch (record.__typename) {
                  case "DatoCmsImage":
                    return <ImageBlock record={record} />
                  case "DatoCmsVideo":
                    return <VideoBlock record={record} />
                  case "DatoCmsHighlightText":
                    return (
                      <HighlightText title={record.title} body={record.body} />
                    )
                  case "DatoCmsTable":
                    return <TableBlock record={record} />
                  case "DatoCmsGallery":
                    return <Gallery record={record} />
                  case "DatoCmsVideoEmbed":
                    return <VideoEmbedBlock record={record} />
                  default:
                    return null
                }
              }}
            />
          </Container>
        </Layout>
      </i18nContext.Provider>
    </LocaleContext.Provider>
  )
}

export default Article

export const query = graphql`
  query ArticleQuery($id: String!, $locale: String!) {
    article: datoCmsArticle(id: { eq: $id }) {
      id
      title
      locale
      date(locale: $locale, formatString: "DD MMMM Y")
      category {
        name
      }
      _allSlugLocales {
        value
        locale
      }
      meta {
        firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
      }
      image {
        gatsbyImageData(width: 900, height: 600, placeholder: BLURRED)
      }
      structuredBody {
        value
        blocks {
          __typename
          ... on DatoCmsImage {
            id: originalId
            image {
              url
              title
            }
            url
            blank
          }
          ... on DatoCmsVideo {
            id: originalId
            media {
              url
            }
          }
          ... on DatoCmsVideoEmbed {
            id: originalId
            embedCode
          }
          ... on DatoCmsHighlightText {
            id: originalId
            title
            body
          }
          ... on DatoCmsTable {
            id: originalId
            body
          }
          ... on DatoCmsGallery {
            id: originalId
            layout
            images {
              title
              gatsbyImageData(width: 700, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
