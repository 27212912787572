import { Box } from "@theme-ui/components"
import React from "react"

const VideoBlock = ({ record }) => {
  //   console.log(record)
  return (
    <Box as="video" sx={{ maxWidth: "100%", mb: 4 }} controls>
      <source src={record.media.url} type="video/mp4" />
    </Box>
  )
}

export default VideoBlock
