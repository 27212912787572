import { Box, Divider, Grid, Text } from "@theme-ui/components"
import React from "react"

const HighlightText = ({ title, body }) => (
  <Grid
    sx={{
      position: ["relative"],
      width: ["100%", "100%", "120%"],
      left: ["0", "0", "-10%"],
    }}
    columns={[1, 1, "1fr 2fr"]}
    mb={[3, 3, 4]}
    mt={5}
    gap={[2,2,4]}
  >
    <Text
      as="h3"
      variant="h3"
      sx={{
        fontWeight: "bold",
        fontSize: [4, 4, 5],
      }}
    >
      {title}
      <Divider
        sx={{
          color: "primary",
          backgroundColor: "primary",
          height: 10,
          mt: 4,
          width: ["50%", "70%"],
        }}
      />
    </Text>
    <Box dangerouslySetInnerHTML={{ __html: body }} />
  </Grid>
)

export default HighlightText
